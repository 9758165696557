import { useCallback, useEffect, useState } from 'react';

import type { CreateToast, Toast } from '@contexts/AppContext';

const maxToastsAtSameTime = 3;

const useToasts = () => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const startHidingToast = useCallback(
    (callback: (toasts: Toast[]) => void | null = null) => {
      const toastsAux = JSON.parse(JSON.stringify(toasts));

      toastsAux[0] = {
        ...toastsAux[0],
        hiding: true,
      };

      if (typeof callback === 'function') {
        callback(toastsAux);
      } else {
        setToasts(toastsAux);
      }
    },
    [toasts],
  );

  const createToast: CreateToast = (status, title = '', description = '') => {
    if (toasts.length === maxToastsAtSameTime) {
      startHidingToast((callbackToast) =>
        setToasts([
          ...callbackToast,
          {
            id: toasts.length > 0 ? toasts[toasts.length - 1].id + 1 : 0,
            status,
            title,
            description,
            hiding: false,
          },
        ]),
      );
    } else if (toasts.length < maxToastsAtSameTime) {
      setToasts([
        ...toasts,
        {
          id: toasts.length > 0 ? toasts[toasts.length - 1].id + 1 : 0,
          status,
          title,
          description,
          hiding: false,
        },
      ]);
    }
  };

  const removeToast = useCallback(() => {
    const filteredToasts = toasts.slice(1);
    setToasts([...filteredToasts]);
  }, [toasts]);

  useEffect(() => {
    if (toasts.length > 0) {
      for (let i = 0; i < toasts.length; i++) {
        if (toasts[i].hiding) {
          const timerRemove = setTimeout(() => {
            removeToast();
          }, 500);

          return () => clearTimeout(timerRemove);
        }

        const timerHiding = setTimeout(() => {
          startHidingToast();
        }, 1500);

        return () => clearTimeout(timerHiding);
      }
    }
  }, [removeToast, startHidingToast, toasts]);

  return { toasts, createToast };
};

export default useToasts;
