import { createContext, useContext } from 'react';

import { PopAnalytics, popAnalytics } from '../utils/PopAnalytics';

interface PopAnalyticsContextState {
  popAnalytics: PopAnalytics;
}

export const PopAnalyticsContext = createContext<PopAnalyticsContextState>({
  popAnalytics,
});

export const usePopAnalytics = (): PopAnalyticsContextState =>
  useContext(PopAnalyticsContext);
