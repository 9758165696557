import React, { createContext, type FC } from 'react';

import useLoggedInUser from '@hooks/useLoggedInUser';
import useSegment from '@hooks/useSegment';
import useToasts from '@hooks/useToasts';

export type CreateToast = (
  type: string,
  title?: string,
  description?: string,
) => void;

export interface Toast {
  description: string;
  hiding: boolean;
  id: number;
  status: string;
  title: string;
}

// Corresponds to ../gql/me.gql
export interface User {
  analytics_user_id: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  user_plan: string;
}

export type AppContext = {
  createToast: CreateToast;
  isLoggedIn: boolean;
  toasts: Toast[];
  user: User | null;
};

const DEFAULT_APP_STATE = {
  createToast: () => {},
  isLoggedIn: false,
  toasts: [],
  user: null,
};

export const Context = createContext<AppContext>(DEFAULT_APP_STATE);

interface ProviderProps {
  children: React.ReactNode;
}

export const Provider: FC<ProviderProps> = ({ children }) => {
  const { user } = useLoggedInUser();
  const { toasts, createToast } = useToasts();
  useSegment();

  return (
    <Context.Provider
      value={{
        createToast,
        isLoggedIn: !!user,
        user,
        toasts,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default { Context, Provider };
