import { useQuery } from '@apollo/client';
import React, { FC, useCallback, useMemo, useState } from 'react';

import globalFeaturesGql from '../gql/globalFeatures.gql';

export const AnnouncementBanner: FC = () => {
  const { data } = useQuery(globalFeaturesGql, {
    context: {
      isPublic: true,
    },
    variables: {
      only: ['announcementBanner'],
    },
  });
  const announcementBanner = data?.globalFeatures?.announcementBanner;

  const [announcementBannerId, announcementBannerContent] = useMemo(() => {
    if (!announcementBanner) {
      return [null, null];
    }
    const match = /(?:(\w+)\s*:\s*)?(.+)/.exec(announcementBanner);
    if (!match) {
      return [null, null];
    }
    const [, id, content] = match;
    return [id || content, content];
  }, [announcementBanner]);

  const [viewedAnnouncement, setViewedAnnouncement] = useState(() =>
    typeof localStorage === 'undefined'
      ? null
      : localStorage.getItem('viewedAnnouncement'),
  );

  const dismissAnnouncement = useCallback(() => {
    if (!announcementBannerId) {
      return;
    }
    localStorage.setItem('viewedAnnouncement', announcementBannerId);
    setViewedAnnouncement(announcementBannerId);
  }, [announcementBannerId]);

  return announcementBannerContent &&
    announcementBannerId &&
    announcementBannerId !== viewedAnnouncement ? (
    <div className="flex flex-row items-center p-4 min-h-[60px] bg-gradient-to-r from-[#FFECE3] to-[#FFCDDC] text-medium font-medium text-black leading-5">
      <div
        dangerouslySetInnerHTML={{
          __html: announcementBannerContent,
        }}
        className="flex-auto"
      />
      <div
        className="cursor-pointer flex-none text-gray-700 hover:text-black"
        onClick={dismissAnnouncement}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  ) : null;
};
