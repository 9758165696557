declare global {
  interface Window {
    popAnalytics?: PopAnalytics;
  }
}

export type Traits = Record<
  string,
  | string
  | number
  | boolean
  | Record<string, string | number | boolean | null>
  | null
>;

export interface AnalyticsUser {
  anonymousId: () => string;
  id: () => string;
}

export interface Mixpanel {
  get_distinct_id(): string;
  reset(): void;
}

export interface LoggerInterface {
  debug(msg: string | Error, ...data: unknown[]): void;
  error(msg: string | Error, ...data: unknown[]): void;
  info(msg: string | Error, ...data: unknown[]): void;
  log(msg: string | Error, ...data: unknown[]): void;
  warn(msg: string | Error, ...data: unknown[]): void;
}

export interface SegmentAnalytics {
  SNIPPET_VERSION: string;
  _loadOptions?: { integrations: Record<string, boolean> };
  factory: (t: any) => () => SegmentAnalytics;
  group(
    groupId: number | string,
    traits?: Traits,
    options?: Record<string, unknown>,
    callback?: () => void,
  ): void;
  identify(userId: number | string, traits?: Traits): void;
  identify(traits: Traits): void;
  initialize?: () => void;
  initialized?: boolean;
  invoked: boolean;
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/#load-options
  // load can be `undefined`, after being initialized
  load?(
    apiKey: string,
    options?: { integrations: Record<string, boolean> },
  ): void;
  methods: string[];
  page(properties: Traits, options: Record<string, unknown>): void;
  push(args: any): void;
  ready(callback: () => void): void;
  reset(): void;
  track(event: AnalyticsEvent | `${AnalyticsEvent}`, properties?: Traits): void;
  track(
    event: AnalyticsEvent | `${AnalyticsEvent}`,
    properties?: Traits,
  ): void | Promise<{
    attempts: number;
    id: string;
  }>;
  user: () => AnalyticsUser;
}

// This must be declared to use it within the wrapper here
// We do not want to declare it globally, as it should not be used elsewhere
declare const analytics: SegmentAnalytics;
declare const mixpanel: undefined | Mixpanel;

export enum AnalyticsEvent {
  viewPage = 'View Page',
}

export interface AnalyticsTrackArgs {
  event: AnalyticsEvent | `${AnalyticsEvent}`;
  id?: string;
  mixpanelId: string | null;
  organizationId?: number | null;
  properties: Traits | null;
  segmentId: string | null;
  source: string;
  time?: number;
  userId?: number | null;
}

type EmitTrackTuple = [AnalyticsEvent | `${AnalyticsEvent}`, Traits?];

const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN || 'popsql.com';

// based on https://stackoverflow.com/a/24103596
function setCookie(name: string, value: any, days?: number) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie =
    name + '=' + (value || '') + expires + '; path=/; domain=' + COOKIE_DOMAIN;
}

function getCookie(name: string): string | null {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const getViewPageProps = (url: string) => {
  let initialReferrer = getCookie('initial_referrer') || null;
  if (!initialReferrer) {
    initialReferrer = document.referrer || null;
    setCookie('initial_referrer', initialReferrer);
  }
  const initialReferringDomain =
    (initialReferrer && new URL(initialReferrer).host) || null;

  const params = new URLSearchParams(window.location.search);
  [
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
  ].forEach((name) => {
    const value = params.get(name);
    if (value) {
      setCookie(name, value);
    }
  });

  return {
    currentUrl: window.location.href,
    initialReferrer,
    initialReferringDomain,
    url,
    utmCampaign: getCookie('utm_campaign') || null,
    utmContent: getCookie('utm_content') || null,
    utmMedium: getCookie('utm_medium') || null,
    utmSource: getCookie('utm_source') || null,
    utmTerm: getCookie('utm_term') || null,
  };
};

export class PopAnalytics {
  private logger: LoggerInterface = console;

  private fetchUrl: string | null = null;

  private emitTrackQueue: EmitTrackTuple[] = [];

  private disabled = false;

  private source: string | null = null;

  private userId: number | null = null;

  private organizationId: number | null = null;

  private identified = false;

  init({
    fetchUrl,
    organizationId,
    source,
    userId,
  }: {
    fetchUrl?: string;
    organizationId?: number;
    source: string;
    userId?: number;
  }): this {
    this.userId = userId || null;
    this.organizationId = organizationId || null;
    this.fetchUrl = fetchUrl || null;
    this.source = source;
    window.popAnalytics = this;
    this.logger.debug('PopAnalytics initialized');
    return this;
  }

  setFetchUrl(fetchUrl: string | null) {
    this.fetchUrl = fetchUrl;
    this.logger.debug(`PopAnalytics fetchUrl ${fetchUrl ? 'set' : 'cleared'}`);
    this.drainEmitTrackQueue();
  }

  private drainEmitTrackQueue() {
    const { emitTrackQueue, fetchUrl, identified } = this;
    if (emitTrackQueue.length && identified && fetchUrl) {
      this.logger.debug(`Draining ${emitTrackQueue.length} queued event(s)`);
      this.emitTrackQueue = [];
      emitTrackQueue.forEach(([event, props]) => {
        this.emitTrack(event, props);
      });
    }
  }

  setLogger(logger: LoggerInterface) {
    this.logger = logger;
  }

  private emitTrack(
    event: AnalyticsEvent | `${AnalyticsEvent}`,
    properties?: Traits,
  ) {
    const { fetchUrl } = this;

    // If we don't have a fetchUrl, or we haven't identified the user yet, queue the event
    if (!fetchUrl || !this.identified) {
      this.emitTrackQueue.push([event, properties]);
      return;
    }

    const { mixpanelId, segmentId, source } = this;

    const payload: AnalyticsTrackArgs = {
      event,
      mixpanelId: mixpanelId || null,
      properties: properties || null,
      segmentId: segmentId || null,
      source: source || 'unspecified',
      organizationId: this.organizationId || null,
      userId: this.userId || null,
    };
    fetch(fetchUrl, {
      body: JSON.stringify({
        method: 'track',
        payload,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then(async (response) => {
        if (!response.ok) {
          this.logger.error(
            `PopAnalytics.emitTrack(${event}) failed with status ${
              response.status
            }: ${await response.text()}`,
          );
        }
      })
      .catch(this.logger.error);
  }

  enable() {
    if (!this.disabled) {
      return;
    }
    this.disabled = false;
    this.logger.debug('PopAnalytics enabled');
  }

  disable() {
    if (this.disabled) {
      return;
    }
    this.disabled = true;
    this.logger.debug('PopAnalytics disabled');
  }

  identify(params: { organizationId?: number | null; userId?: number | null }) {
    if (this.disabled) {
      return;
    }
    this.logger.debug('PopAnalytics.identify', params);
    this.userId = params.userId || null;
    this.organizationId = params.organizationId || null;
    this.identified = true;
    this.drainEmitTrackQueue();
  }

  trackPageView(url: string) {
    this.track(AnalyticsEvent.viewPage, getViewPageProps(url));
  }

  track(
    event: AnalyticsEvent | `${AnalyticsEvent}`,
    properties?: Traits,
  ): void {
    if (this.disabled) {
      return;
    }
    this.logger.debug(`PopAnalytics.track(${event})`, properties);
    this.emitTrack(event, properties);
  }

  get mixpanelId(): string {
    return (
      (!this.disabled &&
        typeof mixpanel !== 'undefined' &&
        typeof mixpanel?.get_distinct_id === 'function' &&
        mixpanel.get_distinct_id()) ||
      ''
    );
  }

  get segmentId(): string {
    return this.user().anonymousId() || '';
  }

  user(): AnalyticsUser {
    if (this.disabled || typeof analytics.user !== 'function') {
      return {
        anonymousId: () => '',
        id: () => '',
      };
    }
    return analytics.user();
  }
}

// Singleton
export const popAnalytics = new PopAnalytics();
