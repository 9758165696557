import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

export const apolloClient = new ApolloClient({
  assumeImmutableResults: true,
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    }),
    new RetryLink().split(
      (operation) => operation.getContext().isPublic === true,
      new HttpLink({
        uri: `${process.env.NEXT_PUBLIC_WEB_HOST}/graphql-public`,
      }),
      new HttpLink({
        uri: `${process.env.NEXT_PUBLIC_WEB_HOST}/graphql`,
        credentials: 'include',
      }),
    ),
  ]),
  cache: new InMemoryCache(),
});
