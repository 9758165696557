import React, { FC, useContext } from 'react';
import { createPortal } from 'react-dom';

import { Context } from '../../contexts/AppContext';
import Toast from '../Toast';

interface Props {
  position?: string;
}

const Toasts: FC<Props> = ({ position = 'bottom-right' }) => {
  const { toasts } = useContext(Context);

  let top = 'unset';
  let topMobile = 'unset';
  let bottom = 'unset';
  let bottomMobile = 'unset';
  let right = 'unset';
  let rightMobile = 'unset';
  let left = 'unset';
  let leftMobile = 'unset';
  switch (position) {
    case 'top-left':
      top = '20px';
      topMobile = '80px';
      left = '20px';
      leftMobile = '20px';
      break;
    case 'top-right':
      top = '20px';
      topMobile = '80px';
      right = '20px';
      rightMobile = '20px';
      break;
    case 'bottom-left':
      topMobile = '80px';
      bottom = '20px';
      bottomMobile = 'unset';
      left = '20px';
      leftMobile = '20px';
      break;
    case 'bottom-right':
      topMobile = '80px';
      bottom = '20px';
      bottomMobile = 'unset';
      right = '100px';
      rightMobile = '20px';
      break;
    default:
      topMobile = '80px';
      bottom = '20px';
      bottomMobile = 'unset';
      right = '100px';
      rightMobile = '20px';
  }

  const getToasts = () => {
    const auxToasts = JSON.parse(JSON.stringify(toasts));

    if (position.includes('bottom') && window.innerWidth > 768) {
      return auxToasts.reverse().map((toast) => (
        <Toast
          key={toast.id}
          // @ts-expect-error TODO need to check this
          id={toast.id}
          title={toast.title}
          description={toast.description}
          status={toast.status}
          position={position}
          hiding={toast.hiding}
        />
      ));
    }

    return auxToasts.map((toast) => (
      <Toast
        key={toast.id}
        // @ts-expect-error TODO need to check this
        id={toast.id}
        title={toast.title}
        description={toast.description}
        status={toast.status}
        position={position}
        hiding={toast.hiding}
      />
    ));
  };

  if (typeof document !== 'undefined') {
    return (
      <>
        {createPortal(
          <div className="toasts-wrapper">
            {getToasts()}
            <style jsx>{`
              .toasts-wrapper {
                top: ${top};
                bottom: ${bottom};
                right: ${right};
                left: ${left};
                position: fixed;
                transition: transform 0.4s ease 0s;
                height: auto;
                transition: all 0.5s ease;
              }

              @media (max-width: 768px) {
                .toasts-wrapper {
                  top: ${topMobile};
                  bottom: ${bottomMobile};
                  right: ${rightMobile};
                  left: ${leftMobile};
                }
              }
            `}</style>
          </div>,
          document.getElementsByTagName('body')[0],
        )}
      </>
    );
  }

  return null;
};

export default Toasts;
