import { useEffect } from 'react';

const useSegment = () => {
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !(function () {
      window.analytics = window.analytics || [];
      const { analytics } = window;
      if (!analytics.initialize)
        if (analytics.invoked) {
          if (window.console && console.error) {
            console.error('Segment snippet included twice.');
          }
        } else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
          ];
          analytics.factory = function (t) {
            return function () {
              // eslint-disable-next-line prefer-rest-params
              const e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (let t = 0; t < analytics.methods.length; t++) {
            const e = analytics.methods[t];
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function (t, e) {
            const n = document.createElement('script');
            n.type = 'text/javascript';
            n.async = true;
            n.src =
              'https://cdn.segment.com/analytics.js/v1/' +
              t +
              '/analytics.min.js';
            const a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(n, a);
            // eslint-disable-next-line no-underscore-dangle
            analytics._loadOptions = e;
          };
          analytics.SNIPPET_VERSION = '4.5.13';
          analytics.load(process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY);
        }
    })();
  }, []);
};

export default useSegment;
