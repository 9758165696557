import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import React, { useEffect, useRef } from 'react';
import '../css/reset.css';
import '../css/tailwind.css';
import '../css/global.css';
import '../css/fade.css';
import '../css/nprogress.css';
import '../css/confetti.css';
import '../css/slick.css';
import '../css/slick-theme.css';
import '../css/font-inter.css';

import Toasts from '../components/Toasts';
import { Provider as AppProvider } from '../contexts/AppContext';
import { trackPageView } from '../utils/track';

import { AnnouncementBanner } from '@components/AnnouncementBanner';
import { PopAnalyticsContext } from '@contexts/PopAnalyticsContext';
import { apolloClient } from '@utils/apolloClient';
import { popAnalytics } from '@utils/PopAnalytics';

Router.events.on('routeChangeStart', () => {
  NProgress.start();
  // The menu removes scroll, and gives it back onClose
  // But when a link is clicked, onClose doesn't trigger.
  if (typeof document !== 'undefined')
    document.body.style.overflowY = 'initial';
});
Router.events.on('routeChangeComplete', (url) => {
  NProgress.done();
  setTimeout(() => {
    trackPageView(url);
  }, 0);
});
Router.events.on('routeChangeError', () => NProgress.done());

function PopSQLMarketingApp({ Component, pageProps, router }: AppProps) {
  const visitedUrls = useRef(new Set<string>());

  useEffect(() => {
    const fetchUrl = process.env.NEXT_PUBLIC_ANALYTICS_URL;
    if (!fetchUrl) {
      console.error('NEXT_PUBLIC_ANALYTICS_URL is not configured');
      return;
    }
    popAnalytics.init({
      fetchUrl,
      source: 'marketing',
    });
  }, []);

  useEffect(() => {
    // Dark theme is only supported on specific pages
    const newTheme =
      router.asPath.startsWith('/dbt') || router.asPath.startsWith('/notebooks')
        ? 'dark'
        : 'light';
    const classes = document.documentElement.classList;
    if (!classes.contains(newTheme)) {
      classes.remove('light', 'dark');
      classes.add(newTheme);
    }
  }, [router.asPath]);

  useEffect(() => {
    // Checks if the page has been visted previously
    // if so then it removes the 'fade' class
    const handler = (url: string) => {
      if (visitedUrls.current.has(url)) {
        const fadeElements = document.querySelectorAll('.fade');

        if (fadeElements.length > 0) {
          fadeElements.forEach((element) => element.classList.remove('fade'));
        }
      }
      visitedUrls.current.add(url);
    };
    Router.events.on('routeChangeComplete', handler);
    return () => {
      Router.events.off('routeChangeComplete', handler);
    };
  }, []);

  useEffect(() => {
    visitedUrls.current.add(router.route);
  }, [router.route]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) {
      import('react-gtm-module')
        .then(({ default: TagManager }) => {
          TagManager.initialize({
            gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
          });
        })
        .catch(console.error);
    }
  }, []);

  return (
    <PopAnalyticsContext.Provider value={{ popAnalytics }}>
      <ApolloProvider client={apolloClient}>
        <AppProvider>
          <AnnouncementBanner />
          <Component {...pageProps} />
          <Toasts />
        </AppProvider>
      </ApolloProvider>
    </PopAnalyticsContext.Provider>
  );
}

export default PopSQLMarketingApp;
