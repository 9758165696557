import { datadogRum } from '@datadog/browser-rum';

import { popAnalytics } from './PopAnalytics';

if (process.env.DATADOG_DISABLED !== 'true') {
  datadogRum.init({
    allowedTracingOrigins: [/.*\.popsql\.*/],
    applicationId: process.env.DATADOG_APP_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask',
    env: process.env.NODE_ENV,
    sessionReplaySampleRate: 100,
    sampleRate: 3,
    service: 'popsql-marketing',
    site: process.env.DATADOG_SITE,
    trackInteractions: true,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.GIT_SHA,
  });

  datadogRum.startSessionReplayRecording();
}

export const trackPageView = (url = window.location.pathname) => {
  datadogRum.startView(url);
  if (
    typeof window === 'undefined' ||
    typeof window.useSegment === 'undefined'
  ) {
    return;
  }
  // Always send to kafka
  popAnalytics.trackPageView(url);

  // Always track mixpanel
  analytics?.ready?.(() => {
    mixpanel?.track?.('View Page', { Path: url });
  });
  // Conditionally call Segment/GA
  if (window.useSegment) {
    window.analytics?.page?.(url);
  } else {
    window.ga?.('send', 'pageview', { page: url });
  }

  const uid = window.localStorage.getItem('uid');
  const userplan = window.localStorage.getItem('userplan');

  const event = uid
    ? {
        event: 'popData',
        registered: true,
        userplan,
        uid,
      }
    : {
        event: 'popData',
        registered: false,
      };
  sendToDataLayer(event);
};

const MAX_RETRIES = 5;
export const sendToDataLayer = (event, attempt = 0) => {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push(event);
  } else if (attempt < MAX_RETRIES) {
    // If dataLayer has not been loaded yet, let's wait a little
    setTimeout(() => {
      sendToDataLayer(event, attempt + 1);
    }, 1000);
  }
};
