import React from 'react';

const Toast = ({ status, title, position, hiding }) => {
  let background;
  let color;
  switch (status) {
    case 'success':
      background = 'rgba(0,0,0,.85)';
      color = 'white';
      break;
    case 'warning':
      background = '#E1A815';
      color = 'white';
      break;
    case 'error':
      background = '#ED3F3F';
      color = 'white';
      break;
    default:
      background = 'white';
      color = 'black';
      break;
  }

  return (
    <div className={`container ${hiding ? 'hiding' : 'visible'}`}>
      <div>
        <p>{title}</p>
      </div>
      <style jsx>{`
        .container {
          background: ${background};
          padding: 0 20px;
          width: 250px;
          height: 50px;
          color: ${color};
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          border-radius: 6px;
          align-items: center;
          margin-bottom: ${position.includes('bottom') ? 0 : 20}px;
          margin-top: ${position.includes('top') ? 0 : 20}px;
          animation: 0.5s ease 0s 1 normal forwards visible;
          transition: all 0.5s ease;
        }

        .container.hiding {
          animation-name: hiding;
        }

        .container.visible {
          animation-name: visible;
        }

        @keyframes visible {
          0% {
            opacity: 0;
            transform: ${position.includes('bottom') && window.innerWidth > 768
              ? 'translateY(20px)'
              : 'translateY(-20px)'};
          }

          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes hiding {
          100% {
            opacity: 0;
            height: 0;
            margin: 0;
            transform: ${position.includes('bottom') && window.innerWidth > 768
                ? 'translateY(100px)'
                : 'translateY(-100px)'}
              scale(0);
          }
        }

        p {
          font-family: 'Inter', sans-serif;
          color: white;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
};

export default Toast;
