import { useEffect, useState } from 'react';

import meGql from '../gql/me.gql';
import { apolloClient } from '../utils/apolloClient';
import { trackPageView } from '../utils/track';

import type { User } from '@contexts/AppContext';
import { usePopAnalytics } from '@contexts/PopAnalyticsContext';
import type { PopAnalytics, SegmentAnalytics } from '@utils/PopAnalytics';

declare global {
  interface Window {
    analytics?: SegmentAnalytics;
    useSegment?: boolean;
  }
}

const fetchUser = async (): Promise<User | null> => {
  try {
    const {
      data: { me },
    } = await apolloClient.query({
      query: meGql,
    });
    return me;
  } catch (err) {
    console.error('Error fetching user data: ', err);
    return null;
  }
};

const initAnalytics = async (
  popAnalytics: PopAnalytics,
  user: User | null,
): Promise<void> => {
  if (user) {
    if (user.analytics_user_id) {
      window.localStorage.setItem('uid', user.analytics_user_id);
    }
    if (user.user_plan) {
      window.localStorage.setItem('userplan', user.user_plan);
    }
  } else {
    window.localStorage.removeItem('uid');
    window.localStorage.removeItem('userplan');
  }

  const popId = () => {
    popAnalytics.identify({
      userId: user?.id,
    });
  };
  if (window.analytics?.ready) {
    // it is possible that the analytics library will never load, if blocked by an ad blocker
    // so we give it a timeout
    Promise.race([
      new Promise<void>((resolve) => setTimeout(resolve, 3000)),
      new Promise<void>((resolve) => window.analytics.ready(resolve)),
    ])
      .catch(console.error)
      .finally(popId);
  } else {
    popId();
  }

  if (!window.useSegment) {
    window.useSegment = Boolean(user);
  }
  if (window.analytics) {
    if (user) {
      window.analytics.identify(user.id, {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
      });
    }
    trackPageView();
  }
};

const useLoggedInUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const { popAnalytics } = usePopAnalytics();

  useEffect(() => {
    (async () => {
      if (user) {
        return;
      }

      const newUser = await fetchUser();
      await initAnalytics(popAnalytics, newUser);

      setUser(newUser);
    })();
  }, [popAnalytics, user]);

  return { user };
};

export default useLoggedInUser;
